.loading {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-family: __FTPolarMedium_6973ac, __FTPolarMedium_Fallback_6973ac,
    'Helvetica Neue', sans-serif;
  font-weight: 500;
  /* font-size: 24px; */
  /* color: #8a8fa4; */
  /* color: var(--green-200); */
  max-width: 100%;
  line-height: 1.35;
}
