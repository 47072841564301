.appearance-id-0 {
  background: linear-gradient(276.58deg, #01e2a0 -0.6%, #052cc0 102.8%);
}

.appearance-id-1 {
  background: linear-gradient(276.33deg, #ff43ca -14.55%, #052cc0 102.71%);
}

.appearance-id-2 {
  background: linear-gradient(276.33deg, #20e4ff -14.55%, #052cc0 102.71%);
}

.appearance-id-3 {
  background: linear-gradient(94.8deg, #00ab84 -1.2%, #052cc0 103.67%);
}

.appearance-id-4 {
  background: linear-gradient(94.62deg, #ce0d98 -10.14%, #052cc0 104.1%);
}

.appearance-id-5 {
  background: linear-gradient(276.33deg, #052cc0 -14.55%, #0dcae4 102.71%);
}

.appearance-id-6 {
  background: linear-gradient(90.89deg, #003057 -2.21%, #03d597 102.16%);
}

.appearance-id-7 {
  background: linear-gradient(276.23deg, #f31dbe -2.1%, #003057 102.67%);
}

.appearance-id-8 {
  background: linear-gradient(276.48deg, #003057 -0.14%, #052cc0 102.77%);
}

.appearance-id-9 {
  background: linear-gradient(276.32deg, #1af4b5 -5.15%, #0ba97d 102.7%);
}

.appearance-id-10 {
  background: linear-gradient(276.23deg, #e225b3 -2.1%, #7e0d5f 102.67%);
}

.appearance-id-11 {
  background: linear-gradient(276.48deg, #1f48e2 -0.14%, #040b72 102.77%);
}